import i18n from '@/plugins/i18n';

const getDecodedData = response => {
  const enc = new TextDecoder('utf-8');
  return enc.decode(response);
};

const getResponseData = (type, data) => {
  return (type === 'arraybuffer' ? JSON.parse(getDecodedData(data)) : data)?.data;
};

const getErrorObject = error => {
  const object = {
    isShowSnackbar:
      (error && error.response && error.response.config && !error.response.config.skipDefaultErrorHandler) ||
      !error.response ||
      !error.request,
    skipDefaultErrorHandler:
      error && error.response && error.response.config && error.response.config.skipDefaultErrorHandler,
  };
  if (error.response) {
    const data = getResponseData(error.config.responseType, error.response.data);
    const { error_code, error_message, error: notTranslatedError } = data;
    const isUnhandled = error.response.status === 500;
    const errorObject = {
      message: isUnhandled
        ? i18n.t('errors.errorCode', { code: error_code })
        : error_message || notTranslatedError || i18n.t('errors.api.requestGenerateError'),
      timeout: isUnhandled ? -1 : 5000,
    };
    const isLogout = notTranslatedError === 'AccessError.InvalidToken"';
    return { ...object, ...errorObject, isLogout, type: 'response' };
  }
  if (error.request) {
    return { ...object, message: i18n.t('errors.api.requestError'), type: 'request' };
  }
  if (error.error_message) {
    return { ...object, message: error.error_message, type: 'error' };
  }
  return { ...object, message: i18n.t('errors.api.requestGenerateError'), type: 'unidentified' };
};

export { getErrorObject };
